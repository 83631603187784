import React, { useState } from 'react';
import { Form, Select, Upload, App, Alert } from 'antd';
import Button from 'components/Button/Button';
import { UploadOutlined } from '@ant-design/icons';
import Text from 'components/Text/Text';
import Space from 'components/Space/Space';
import { createFinixFileResource, verifyFinixMerchant } from 'services/api/landlordApi/create';
import PropTypes from 'prop-types';
import { uploadFinixMerchantFile } from 'services/api/landlordApi/update';
import { motion } from 'framer-motion';
import { formVariants } from 'animations/variants';
import styled from 'styled-components';
import Result from 'components/uielements/Result/Result';

const { useForm } = Form;
const { Dragger } = Upload;

const documentTypes = {
  DRIVERS_LICENSE_FRONT: "Driver's License Front",
  DRIVERS_LICENSE_BACK: "Driver's License Back",
  IDENTIFICATION_CARD_FRONT: 'Identification Card Front',
  IDENTIFICATION_CARD_BACK: 'Identification Card Back',
  PASSPORT: 'Passport',
  BANK_STATEMENT: 'Bank Statement',
  TAX_DOCUMENT: 'Tax Document',
  BUSINESS_REGISTRATION: 'Business Registration',
  BUSINESS_ADDRESS_VERIFICATION: 'Business Address Verification',
  OTHER: 'Other Document',
};

export const createFileResource = async (file, merchantId) => {
  try {
    const createFileRequest = {
      display_name: file.name,
      linked_to: merchantId,
      type: file.documentType,
    };

    return await createFinixFileResource(createFileRequest);
  } catch (err) {
    console.error(err);
    throw new Error(err.message || 'An error occurred while creating the file resource.');
  }
};

export default function FinixFileUploadForm(props) {
  // console.log('[FinixFileUploadForm.js]', props);
  const { fileList, setFileList, merchantId, tourRef1 } = props;
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = useForm();
  const { message } = App.useApp();

  function handleValuesChange(changedValues) {
    if (changedValues.documentType) {
      setUploadDisabled(false);
    } else {
      setUploadDisabled(true);
    }
  }

  function handleReset() {
    setUploadDisabled(true);
    form.resetFields();
    setFileList([]);
  }

  async function handleFinish() {
    // console.log('ƒ handleFinish');
    try {
      setIsSubmitting(true);
      await Promise.all(
        fileList.map(async (file) => {
          try {
            const fileResource = await createFileResource(file, merchantId);
            const url = `${process.env.REACT_APP_FINIX_API_URL}/files/${fileResource.id}/upload`;

            const formData = new FormData();
            formData.append('file', file);

            return await uploadFinixMerchantFile(url, formData);
          } catch (err) {
            console.error(err);
            throw new Error(err.message || 'An error occurred while uploading the file.');
          }
        })
      ).then(async () => {
        await verifyFinixMerchant(merchantId);
      });

      // message.success('File(s) uploaded successfully.');
      setUploadSuccess(true);
      setIsSubmitting(false);
      handleReset();
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
      message.error(err.message || 'An error occurred while submitting the form. Please try again.');
    }
  }

  return (
    <div ref={tourRef1}>
      <Space vertical={18} />
      <Text color="black">
        Upload the required verification documents using the <strong>Document Type</strong> dropdown to classify
        each document. You can submit more than one document. This information is encrypted and transmitted using
        bank level security.
      </Text>
      <Space vertical={18} />
      <Text color="red" strong>
        Failure to provide ALL required documents will result in application rejection.
      </Text>
      <Space vertical={18} />
      {props.remediationList?.length ? (
        <motion.div variants={formVariants} initial="hide" animate="show">
          <Alert
            style={{ textAlign: 'left' }}
            message={<Text color="black">The following remediations are required:</Text>}
            description={
              <ul>
                {props.remediationList.map((doc, i) => (
                  <li key={i}>
                    <Text color="black" strong>
                      {doc.description}
                    </Text>
                    <br />
                    <Text color="black">{doc.remediationItem}</Text>
                  </li>
                ))}
              </ul>
            }
            type="info"
          />
          <Space vertical={18} />
        </motion.div>
      ) : null}

      {uploadSuccess ? (
        <motion.div variants={formVariants} initial="hide" animate="show">
          <Result
            key="file-upload-result"
            status="success"
            title="Documents Successfully Uploaded!"
            subTitle="You can close this tab. We'll be in touch!"
          />
        </motion.div>
      ) : (
        <Form
          key="file-upload-form"
          name="finix-file-upload-form"
          data-testid="finixFileUploadFormTest"
          form={form}
          layout="vertical"
          disabled={props.disabled}
          onValuesChange={handleValuesChange}
          requiredMark={false}
        >
          <Form.Item name="documentType" label="Document Type" rules={[{ required: true }]}>
            <Select data-testid="documentTypeSelectTest">
              {Object.keys(documentTypes).map((key) => (
                <Select.Option key={key} value={key}>
                  {documentTypes[key]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="file" rules={[{ required: true }]}>
            <StyledDragger
              name="file"
              className="file-upload-dragger"
              data-testid="fileUploadTest"
              disabled={uploadDisabled}
              accept="image/png, image/jpg, image/jpeg, application/pdf"
              fileList={fileList}
              beforeUpload={async (file) => {
                try {
                  file.documentType = form.getFieldValue('documentType');
                  setFileList((prevList) => [...prevList, file]);
                  form.setFieldsValue({ documentType: '' });
                } catch (err) {
                  console.error(err);
                }

                return false;
              }}
              onRemove={(file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
              }}
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Upload single jpeg, png, or pdf file format only.</p>
            </StyledDragger>
          </Form.Item>
          {props.showSubmitButton ? (
            <Form.Item>
              <Button
                alignment="center"
                type="primary"
                size="large"
                onClick={handleFinish}
                disabled={fileList.length === 0}
                loading={isSubmitting}
                data-testid="submitButtonTest"
              >
                Upload
              </Button>
            </Form.Item>
          ) : null}
        </Form>
      )}
    </div>
  );
}

FinixFileUploadForm.propTypes = {
  documentList: PropTypes.array,
  merchantId: PropTypes.string,
  disabled: PropTypes.bool,
};

FinixFileUploadForm.defaultProps = {
  documentList: [],
  merchantId: '',
  disabled: false,
};

const StyledDragger = styled(Dragger)`
  .ant-upload-list.ant-upload-list-text {
    text-align: left;
  }
`;
