import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Space as AntSpace } from 'antd';

import RentCredTitle from 'screens/Tenant/components/RentCredTitle/RentCredTitle';
import Text from 'components/Text/Text';
import Button from 'components/Button/Button';
import PoweredByLvble from './components/PoweredByLvble';
import LvbleWidget from 'components/Lvble/components/LvbleWidget';
import { useDispatch, useSelector } from 'react-redux';
import { initializeLvbleExperience } from 'store/actions/tenantActions';
import { StyledLvbleCard, StyledLvbleContainer } from './Lvble.styles';
import AlertFade from 'components/AlertFade/AlertFade';
import { useLvbleContext } from '@lvble/react';
import { Link } from 'react-router-dom';

export default function Lvble(props) {
  // console.log('ƒ Lvble', props);
  const { alert, setAlert, billingAccountRef } = props;

  const [isDisabled, setIsDisabled] = useState(true);
  const [showWidget, setShowWidget] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const lvbleInitializedRef = useRef(false);
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const lvble = useSelector((store) => store.tenant.lvbleAccount);
  const tenant = useSelector((store) => store.tenant);
  const { rentCred, scheduledPayments } = tenant;
  const { lvble: lvbleExp } = useLvbleContext();

  const checkEligibility = useCallback(
    (rc, t) => {
      // console.log('ƒ checkEligibility', rc, t);
      if (
        (!!rc && rc.points < 1) ||
        t.paymentSettings?.customer?.state !== 'active' ||
        scheduledPayments?.items.length > 0
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    },
    [scheduledPayments.items.length]
  );

  const initializeLvble = useCallback(
    async (userData) => {
      console.log('ƒ initializeLvble', userData);
      try {
        const { property } = userData;

        const addressArr = property.address.match(/^(\d+\s1\/2|[0-9]+-*\d+|\d+)(?:\s+)(.*)$/);
        // console.log(addressArr.length);
        let streetName, streetNumber;
        if (addressArr) {
          streetNumber = addressArr[1];
          streetName = addressArr[2];
        } else {
          streetName = property.address;
        }

        dispatch({ type: 'SET_LVBLE_TOKEN_LOADING' });

        const data = {
          address: {
            street_number: streetNumber || '',
            street_name: streetName,
            apt_number: property.address2,
            city: property.city,
            zip: property.zip,
            state: property.state,
          },
          property_id: property.id,
          id: userData.id,
          name: userData.firstName,
          surname: userData.lastName,
          phone_number: userData.phone || '',
          email: userData.email,
          rent_due_day: { day_of_month: property.rentDueDay },
          base_rent_amount_in_cents: Math.round(property.rentAmount * 100),
          open_balance: Math.round(billingAccountRef?.current?.balance * 100),
        };
        // console.log('data', data);
        dispatch(initializeLvbleExperience(session.cognitoTokens.idToken.jwtToken, data, 'EXPERIENCE_CLOSED'));
      } catch (error) {
        console.error(error);
      }
    },
    [billingAccountRef, dispatch, session.cognitoTokens.idToken.jwtToken]
  );

  function handleOpenLvble(lvble) {
    if (lvble.token) {
      dispatch({ type: 'SET_LVBLE_EXPERIENCE', payload: 'EXPERIENCE_OPENED' });
      lvbleExp.open({ token: lvble.token });
    } else {
      console.error('No lvble token');
      setAlert({ type: 'error', msg: 'Not initialized. Please reload page.', visible: true });
    }
  }

  useEffect(() => {
    // console.log('ƒ useEffect on session', tenant.billingItems);
    if (session.userData) {
      !session.userData.property.commercial && setShowCard(true);
    }

    if (!lvble.token && session.userData && !tenant.billingItems.loading && !lvbleInitializedRef.current) {
      initializeLvble(session.userData);
      lvbleInitializedRef.current = true;
    }
  }, [tenant.billingItems, session.userData, lvble.token, initializeLvble]);

  useEffect(() => {
    // console.log('ƒ useEffect on lvble', lvble, rentCredStatus);
    if (!lvble.account) {
      return;
    } else if (lvble.account.hasBalance) {
      setShowWidget(true);
    } else {
      checkEligibility(rentCred, tenant);
      setShowWidget(false);
    }
  }, [lvble, rentCred, tenant, checkEligibility]);

  /**
   * use this to test backend payment processing along with the test payment button
   */
  // const testData = { amount: 110000, settlement_id: 69, charges_paid: [] };

  return (
    <>
      {showCard ? (
        <StyledLvbleCard
          className="content-col rent-cred-status"
          title={<RentCredTitle subtitle="Float" />}
          extra={<PoweredByLvble />}
        >
          {showWidget ? (
            <LvbleWidget />
          ) : (
            <StyledLvbleContainer id="lvble-container" data-testid="lvbleContainerTest">
              <AntSpace direction="vertical" align="center" size={18}>
                <Text color="black" size={18} weight={600} centered>
                  Pay Your Rent In{' '}
                  <Text size={18} color="lvbleGreen">
                    Installments
                  </Text>
                </Text>
                <Text color="black" size={14}>
                  PayRent has partnered with Livble to help you avoid late fees and build your credit. Pay rent on
                  a schedule that works for you!
                </Text>
                {lvble.account?.status !== 'DECLINED' ? (
                  <>
                    <Button
                      color="lvbleGreen"
                      size="large"
                      onClick={() => handleOpenLvble(lvble)}
                      disabled={isDisabled}
                      loading={lvble.loading}
                      data-testid="lvbleButtonTest"
                    >
                      {lvble.account?.status === 'CAN_USE_SERVICE' ? 'Pay With Lvble' : 'Add Lvble'}
                    </Button>

                    <div style={{ height: '40px' }}>
                      {!!rentCred && rentCred.points === 0 ? (
                        <Text color="black" size={12}>
                          * Feature available after one on-time payment.
                        </Text>
                      ) : scheduledPayments?.items.length > 0 ? (
                        <Text color="black" size={12}>
                          * Please delete your <Link to={{ pathname: '/tenant/wallet' }}>scheduled payments</Link>{' '}
                          before using this feature.
                        </Text>
                      ) : null}
                      {alert && <AlertFade isVisible={alert.visible} message={alert.msg} type={alert.type} />}
                    </div>
                  </>
                ) : (
                  <AlertFade
                    isVisible={true}
                    message="Unfortunately you are not eligible for this service"
                    type="error"
                  />
                )}
              </AntSpace>
            </StyledLvbleContainer>
          )}
        </StyledLvbleCard>
      ) : null}
    </>
  );
}
