import React, { useState, useEffect, useCallback } from 'react';
import { LvbleProvider as LvbleProviderComponent } from '@lvble/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBillingItems,
  getLvbleAccount,
  initializeLvbleExperience,
  updateLvbleAccount,
} from 'store/actions/tenantActions';
import * as tenantApi from 'services/api/tenantApi';
import { getUserDataV3 } from 'store/actions/sessionActions';
import { useRef, useMemo } from 'react';
import { Environment } from '@lvble/core';
import { cloudwatchLogger } from 'services/api/common/create';
import dayjs from 'dayjs';

const ENV = process.env.REACT_APP_ENV === 'production' ? 'Production' : 'Sandbox';

function LvbleProvider(props) {
  // console.log('ƒ LvbleProvider', props);
  const { children } = props;
  const [alert, setAlert] = useState({ type: 'info', msg: 'Message goes here', visible: false });
  const tenant = useSelector((store) => store.tenant);
  const splitRentRef = useRef({});
  const billingAccountRef = useRef({ balance: 0 });
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);

  // const paymentGateway = useMemo(() => session.userData.paymentGateway, [session.userData.paymentGateway]);
  // const paymentSettings = useMemo(() => tenant.paymentSettings, [tenant.paymentSettings]);

  const handleLvblePaymentV2 = useCallback(
    async (data = {}) => {
      try {
        // check for duplicate event
        if (data.settlement_id === splitRentRef.current.settlement_id) return;
        data.timestamp = dayjs().toISOString();
        await tenantApi.postLvblePayment(session.cognitoTokens.idToken.jwtToken, data);
        setTimeout(() => {
          dispatch(getLvbleAccount(session.cognitoTokens.idToken.jwtToken, session.userData.id));
          dispatch(getBillingItems());
          dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
        }, 30000);
      } catch (err) {
        console.error('ƒ handleLvblePaymentV2 error: ', err);
        cloudwatchLogger(
          session.cognitoTokens.idToken.jwtToken,
          `PayRent App ƒ handleLvblePaymentV2 ERROR: ${err}`
        );
        setAlert({ type: 'error', msg: `The service is currently not available.`, visible: true });
      }
    },
    [dispatch, session.cognitoTokens.idToken.jwtToken, session.userData.id]
  );

  // deprecated 12-13-2024 using webhooks to make and settle lvble payment
  // const handleLvblePayment = useCallback(
  //   async (data = {}) => {
  //     console.log('ƒ handleLvblePayment', data, splitRentRef.current, paymentSettings);
  //     cloudwatchLogger(
  //       session.cognitoTokens.idToken.jwtToken,
  //       `ƒ handleLvblePayment ${JSON.stringify(data)} ${JSON.stringify(splitRentRef.current)} ${JSON.stringify(
  //         paymentSettings
  //       )} `
  //     );
  //     try {
  //       // check for duplicate event
  //       if (data.settlement_id === splitRentRef.current.settlement_id) return;

  //       splitRentRef.current = data;

  //       const paymentMethod = {
  //         type: 'payment-methods',
  //         id:
  //           paymentGateway === 'Wepay'
  //             ? process.env.REACT_APP_LVBLE_PAYMENT_METHOD
  //             : paymentGateway === 'Finix'
  //             ? process.env.REACT_APP_LVBLE_FINIX_PAYMENT_METHOD
  //             : new Error('Payment gateway not support for lvble'), // local development '7e208739-b831-4db1-9111-45ec719ff7d8',
  //       };
  //       const paymentAmount = data.amount / 100 || 0;

  //       const result = await tenantApi.makePayment(
  //         session.userData.paymentGateway,
  //         paymentAmount,
  //         paymentMethod,
  //         billingAccountRef.current,
  //         paymentSettings,
  //         session.cognitoTokens.idToken.jwtToken,
  //         session.userData,
  //         null,
  //         data
  //       );
  //       // console.log('ƒ handleLvblePayment/makePayment', result);
  //       cloudwatchLogger(session.cognitoTokens.idToken.jwtToken, result);

  //       const settlementData = {
  //         ...data,
  //         payment_id: result.id,
  //         tenant_id: session.userData.id,
  //         created_at: result.createdAt,
  //       };

  //       await tenantApi.settleLvblePayment(session.cognitoTokens.idToken.jwtToken, settlementData);
  //       dispatch(getLvbleAccount(session.cognitoTokens.idToken.jwtToken, session.userData.id));
  //       dispatch(getBillingItems());
  //       dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
  //     } catch (err) {
  //       console.error('ƒ handleLvblePayment error: ', err);
  //       cloudwatchLogger(session.cognitoTokens.idToken.jwtToken, `ƒ handleLvblePayment ERROR: ${err}`);
  //       setAlert({ type: 'error', msg: `The service is currently not available.`, visible: true });
  //     }
  //   },
  //   [dispatch, paymentGateway, paymentSettings, session.cognitoTokens.idToken.jwtToken, session.userData]
  // );

  const handleLvbleExperienceError = useCallback(
    (error) => {
      console.log('ƒ handleLvbleExperienceError', error);
      dispatch(initializeLvbleExperience(session.cognitoTokens.idToken.jwtToken, session.userData));
    },
    [dispatch, session.cognitoTokens.idToken.jwtToken, session.userData]
  );

  const onEvent = useCallback(
    (eventType, data) => {
      console.log('ƒ onEvent', eventType, data);
      switch (eventType) {
        case 'EXPERIENCE_OPENED':
          // dispatch({ type: 'SET_LVBLE_EXPERIENCE', payload: 'EXPERIENCE_OPENED' });
          break;
        case 'EXPERIENCE_CLOSED':
          dispatch({ type: 'SET_LVBLE_EXPERIENCE', payload: 'EXPERIENCE_CLOSED' });
          break;
        case 'PRESUBSCRIBED':
          dispatch(
            updateLvbleAccount(session.cognitoTokens.idToken.jwtToken, session.userData.id, {
              status: 'PRESUBSCRIBED',
            })
          );
          break;
        case 'USER_APPROVED':
          dispatch(
            updateLvbleAccount(session.cognitoTokens.idToken.jwtToken, session.userData.id, {
              status: 'CAN_USE_SERVICE',
            })
          );
          break;
        case 'USER_DECLINED':
          dispatch(
            updateLvbleAccount(session.cognitoTokens.idToken.jwtToken, session.userData.id, {
              status: 'DECLINED',
            })
          );
          break;
        case 'SPLIT_RENT':
          // console.log(eventType, data);
          handleLvblePaymentV2(data);
          break;
        case 'ERROR':
          handleLvbleExperienceError(data);
          break;
        default:
          console.log('no case for: ', eventType, data);
          break;
      }
    },
    [
      dispatch,
      handleLvbleExperienceError,
      handleLvblePaymentV2,
      session.cognitoTokens.idToken.jwtToken,
      session.userData.id,
    ]
  );

  useEffect(() => {
    if (session.userData) {
      const activeBillingAccount = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      if (activeBillingAccount.length) {
        billingAccountRef.current = {
          id: activeBillingAccount[0].id,
          type: activeBillingAccount[0].type,
          balance: +activeBillingAccount[0].balance,
          pendingPayments: tenant.billingItems.processing
            .filter((p) => p.refType === 'Payment' && p.state === 'PROCESSING')
            .reduce((acc, cur) => acc + +cur.payment.amount, 0),
        };
        // console.log('[LvbleProviders.js] ƒ useEffect billingAccountRef', billingAccountRef);
      }
    }
  }, [session.userData, tenant]);

  const additionalProps = { alert, setAlert, billingAccountRef };

  return (
    <LvbleProviderComponent environment={Environment[ENV]} onEvent={onEvent}>
      {React.cloneElement(children, additionalProps)}
    </LvbleProviderComponent>
  );
}

export default LvbleProvider;
