import React, { useEffect } from 'react';
import { Input, Form, Row, Col, Select, DatePicker, Radio, Space, Button, Tour, Typography, Alert } from 'antd';
import { layout } from './layout';
import { validatePhone, validateTaxId } from './validators';
import { checkFormReady, stateOptions } from './helpers';
import dayjs from 'dayjs';
import AssociatedOwnersFieldList from './AssociatedOwnersFields';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import FinixFileUploadForm from 'screens/Landing/FinixFileUpload/FinixFileUploadForm';

const { Item, useForm } = Form;

export const requiredFields = [
  'entity_first_name',
  'entity_last_name',
  'entity_title',
  'entity_personal_address_line1',
  'entity_personal_address_city',
  'entity_personal_address_region',
  'entity_personal_address_postal_code',
  'entity_dob',
  'entity_tax_id',
  'entity_phone',
  'entity_email',
  'entity_principal_percentage_ownership',
];

const solePropRequiredDocs = [
  { description: "Valid Driver's License, State ID, or Passport" },
  { description: 'Recent bank statement showing bank account number, account holder name, and bank logo' },
];

const llcRequiredDocs = [
  { description: 'Recent bank statement showing bank account number, account holder name, and bank logo' },
];

const trustRequiredDocs = [
  { description: "Valid Driver's License, State ID, or Passport of trustee" },
  { description: 'Recent bank statement showing bank account number, account holder name, and bank logo' },
];

export default function PersonalProfileForm(props) {
  const [form] = useForm();
  const { handleChangedValues, formValues, setFormReady, fileList, setFileList } = props;
  const [tourOpen, setTourOpen] = React.useState(false);
  const tourRef1 = React.useRef(null);
  const isAcknowledged = React.useRef(false);

  const tourSteps = [
    {
      title: <Space size={25} />,
      description: (
        <Alert
          type="warning"
          showIcon
          message={<Typography.Text strong>IMPORTANT: UPLOAD REQUIRED DOCUMENTS</Typography.Text>}
          description={
            <span>
              It is <strong>mandatory</strong> to upload the documents required for your business type. Not
              uploading these documents will cause your application to be delayed or declined.
            </span>
          }
        />
      ),
      target: () => tourRef1.current,
      closeIcon: false,
      nextButtonProps: { children: 'I Acknowledge' },
    },
  ];

  const handleTourClose = () => {
    setTourOpen(false);
    isAcknowledged.current = true;
  };
  useEffect(() => {
    // console.log(
    //   '[PersonalProfileForm.js] ƒ useEffect on formValues',
    //   formValues
    // );
    form.setFieldsValue(formValues);
  }, [formValues, form]);

  return (
    <>
      <Form
        name="personal-profile-form"
        initialValues={formValues}
        layout="vertical"
        form={form}
        data-testid="personalProfileFormTest"
        onValuesChange={async (changedValues, allValues) => {
          handleChangedValues(changedValues, allValues);

          setTimeout(() => {
            const errors = form.getFieldsError();
            const isReady = checkFormReady('personal_profile_form', allValues, errors);
            // console.log('errors', errors);
            setTourOpen(isReady && !isAcknowledged.current);
            setFormReady((prevFormReady) => ({
              ...prevFormReady,
              1: isReady,
            }));
          }, 500);
        }}
        requiredMark={'optional'}
      >
        <Row gutter={layout.rowGutter}>
          <Col {...layout.threeCol}>
            <Item
              label="First Name"
              name="entity_first_name"
              rules={[
                {
                  required: requiredFields.includes('entity_first_name'),
                },
                { max: 20 },
              ]}
              hasFeedback
            >
              <Input />
            </Item>
          </Col>
          <Col {...layout.threeCol}>
            <Item
              label="Last Name"
              name="entity_last_name"
              rules={[
                {
                  required: requiredFields.includes('entity_last_name'),
                },
                { max: 20 },
              ]}
              hasFeedback
            >
              <Input />
            </Item>
          </Col>
          <Col {...layout.threeCol}>
            <Item
              label="Role"
              name="entity_title"
              rules={[
                {
                  required: requiredFields.includes('entity_title'),
                },
                { max: 60 },
              ]}
              hasFeedback
            >
              <Input />
            </Item>
          </Col>
          <Col {...layout.twoCol}>
            <Item
              label="Personal Street Address"
              name="entity_personal_address_line1"
              rules={[
                {
                  required: requiredFields.includes('entity_personal_address_line1'),
                },
                {
                  message: 'PO Boxes not allowed',
                  validator: (_, value) => {
                    if (
                      value &&
                      (value.toLowerCase().includes('po box') ||
                        value.toLowerCase().includes('p.o. box') ||
                        value.toLowerCase().includes('p o box'))
                    ) {
                      return Promise.reject('PO Boxes not allowed');
                    }
                    return Promise.resolve();
                  },
                },
                { max: 35 },
              ]}
              hasFeedback
            >
              <Input />
            </Item>
          </Col>
          <Col {...layout.twoCol}>
            <Item
              label="Suite or Unit #"
              name="entity_personal_address_line2"
              rules={[
                {
                  required: requiredFields.includes('entity_personal_address_line2'),
                },
                { max: 35 },
              ]}
              hasFeedback
            >
              <Input />
            </Item>
          </Col>
          <Col {...layout.twoCol}>
            <Item
              label="City"
              name="entity_personal_address_city"
              rules={[
                {
                  required: requiredFields.includes('entity_personal_address_city'),
                },

                { max: 20 },
              ]}
              hasFeedback
            >
              <Input />
            </Item>
          </Col>
          <Col {...layout.fourCol}>
            <Item
              label="State"
              name="entity_personal_address_region"
              rules={[
                {
                  required: requiredFields.includes('entity_personal_address_region'),
                },
              ]}
              hasFeedback
            >
              <Select options={stateOptions} />
            </Item>
          </Col>
          <Col {...layout.fourCol}>
            <Item
              label="Zip"
              name="entity_personal_address_postal_code"
              rules={[
                {
                  required: requiredFields.includes('entity_personal_address_postal_code'),
                },

                { max: 5 },
                { min: 5 },
              ]}
              hasFeedback
            >
              <Input />
            </Item>
          </Col>
          <Item name="entity_personal_address_country">
            <Input type="hidden" />
          </Item>
          <Col {...layout.fourCol}>
            <Item
              label="Phone"
              name="entity_phone"
              data-testid="phoneTest"
              rules={[{ required: requiredFields.includes('entity_phone') }, validatePhone]}
              hasFeedback
            >
              <PhoneInput data-testid="phoneInputTest" />
            </Item>
          </Col>
          <Col {...layout.fourCol}>
            <Item
              label="Email"
              name="entity_email"
              data-testid="personalEmailTest"
              rules={[{ required: requiredFields.includes('entity_email') }, { type: 'email' }, { max: 100 }]}
              hasFeedback
            >
              <Input data-testid="personalEmailInputTest" />
            </Item>
          </Col>
          <Col {...layout.fourCol}>
            <Item
              label="Owner SSN"
              name="entity_tax_id"
              data-testid="personalTaxIdTest"
              rules={[
                { required: requiredFields.includes('entity_tax_id') },
                { pattern: /^\d{9}$/, message: 'Must be a 9-digit number' },
                {
                  validator(_, value) {
                    if (
                      !value ||
                      formValues.entity_business_tax_id !== value ||
                      formValues.entity_business_type === 'INDIVIDUAL_SOLE_PROPRIETORSHIP' ||
                      formValues.entity_business_type === 'ASSOCIATION_ESTATE_TRUST'
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Cannot be EIN'));
                  },
                },
                validateTaxId,
              ]}
              hasFeedback
            >
              <Input data-testid="personalTaxIdInputTest" />
            </Item>
          </Col>
          <Col {...layout.fourCol}>
            <Item
              label="Date of Birth"
              name="entity_dob"
              rules={[{ required: requiredFields.includes('entity_dob') }]}
              hasFeedback
            >
              <DatePicker
                style={{ width: '100%' }}
                format="MM/DD/YYYY"
                disabledDate={(current) => {
                  // Can not select days before today and today
                  return current && current > dayjs().subtract(18, 'year');
                }}
              />
            </Item>
          </Col>
          {formValues.entity_business_type !== 'INDIVIDUAL_SOLE_PROPRIETORSHIP' && (
            <Col {...layout.oneCol}>
              <Item
                label="Additional Owners?"
                name="entity_principal_percentage_ownership"
                style={{ textAlign: 'left' }}
                data-testid="ownershipPercentageTest"
                rules={[
                  {
                    required: requiredFields.includes('entity_principal_percentage_ownership'),
                  },
                ]}
                hasFeedback
                help="List all owners with 25% or more ownership."
              >
                <Radio.Group data-testid="ownershipPercentageInputTest">
                  <Space className="inline-radio-button">
                    <Radio value={100}>None</Radio>
                    <Radio value={25}>Other 25%+ Owners</Radio>
                  </Space>
                </Radio.Group>
              </Item>
            </Col>
          )}
        </Row>
        {formValues.entity_business_type !== 'INDIVIDUAL_SOLE_PROPRIETORSHIP' &&
          formValues.entity_principal_percentage_ownership === 25 && (
            <AssociatedOwnersFieldList formValues={formValues} />
          )}
      </Form>
      {formValues.entity_business_type === 'INDIVIDUAL_SOLE_PROPRIETORSHIP' ? (
        <FinixFileUploadForm
          disabled={false}
          remediationList={solePropRequiredDocs}
          fileList={fileList}
          setFileList={setFileList}
          tourRef1={tourRef1}
        />
      ) : formValues.entity_business_type === 'LIMITED_LIABILITY_COMPANY' ? (
        <FinixFileUploadForm
          disabled={false}
          remediationList={llcRequiredDocs}
          fileList={fileList}
          setFileList={setFileList}
          tourRef1={tourRef1}
        />
      ) : formValues.entity_business_type === 'ASSOCIATION_ESTATE_TRUST' ? (
        <FinixFileUploadForm
          disabled={false}
          remediationList={trustRequiredDocs}
          fileList={fileList}
          setFileList={setFileList}
          tourRef1={tourRef1}
        />
      ) : null}
      <Tour open={tourOpen} onClose={() => handleTourClose()} steps={tourSteps} gap={{ offset: 16 }} />
    </>
  );
}
